import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import CsLineIcons from 'path-to-icons/CsLineIcons'; // Update the path as necessary

function SubjectAssessments(props) {
  const {assessmentData} = props
   
  const location = useLocation();
  const history = useHistory()
 
  const query = new URLSearchParams(location.search);
  const subjectID = query.get('subjectID');
  // const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation()
  // const { checkAccessTime, fetchAccessToken } = useService();
  // const location = useLocation();
  // const [assessmentData,setAssessmentData] = useState([])
const currentPath = location.pathname;
  // useEffect(() => {
  //   const access_token = fetchAccessToken();
  //   getAllCommonStudentInitialData({ ep: `student/lesson_assessments?lesson_id=${lessonID}`, token: access_token }).then(
  //     (response) => {
  //       if(response.status){
  //         setAssessmentData(response.data)
  //       }
  //       console.log(response);
  //       console.log('Custom data from after_request:', response);

  //       checkAccessTime(response, currentPath);

  //       // console.log('RESPONSE', response);

  //       // setSubjectData(response.data.data.subjectData);
  //       // setLessonData(response.data.data.lessonData);
  //       // setPartData(response.data.data.partData);
  //     }
  //   );
    
  // }, []);
  // const assessmentData = [
  //   {
  //     assessment_id: 1,
  //     subject_id: 2,
  //     assessment_name: "Math Quiz 1",
  //     total_marks: 50,
  //   },
  //   {
  //     assessment_id: 2,
  //     subject_id: 2,
  //     assessment_name: "Math Assignment 1",
  //     total_marks: 30,
  //   },
  //   {
  //     assessment_id: 3,
  //     subject_id: 102,
  //     assessment_name: "Science Quiz 1",
  //     total_marks: 40,
  //   },
  //   // more assessments
  // ];

  return (
    <Col xl="12" className="mb-5">
      <h2 className="small-title">Assessments</h2>
      <Row className="g-2">
        {assessmentData &&
          assessmentData.length > 0 &&
          assessmentData
            // .filter((assessment) => assessment.subject_id === Number(subjectID))
            .map((assessment, i) => (
              <Col
                xs="6"
                xl="3"
                key={i}
                onClick={() => {
                  if (assessment.isCompleted == 'True') {
                    // Navigate to another route if the exam is completed
                    history.push(`/viewResults?assessment_id=${assessment.id}`);
                  } else {
                  // Handle click events if needed
                  history.push(`/subject/assessment?assessmentId=${assessment.id}`)
                  }
                }}
                className="mb-3"
              >
                <Card className="h-100 hover-scale-up">
                  <Card.Body className="text-center">
                    <NavLink to="#">
                      {/* <CsLineIcons icon="file-text" className="text-primary" /> */}
                      <p className="heading mt-3 text-body">{assessment.assessment}</p>
                      <div className="text-small fw-medium text-muted">
                        No Of Questions: {assessment.no_of_questions}
                      </div>
                      <div className="text-small fw-medium text-muted">
                        Total Marks: {assessment.total_marks}
                      </div>
                      <div className="text-small fw-medium text-muted">
                        Pass Marks: {assessment.pass_marks}
                      </div>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            ))}
      </Row>
    </Col>
  );
}

export default SubjectAssessments;
