/* eslint-disable */
import { lazy } from 'react';
// import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';
import Homework from 'views/dashboards/components/Homework';
import ExamsComponent from 'views/dashboards/components/Exams';
import Assessment from 'views/dashboards/components/Assessment';
import SubjectAssessments from 'views/dashboards/components/AssessmentExam';
import ComingSoon from 'views/dashboards/components/commingSoon';
import SubjectsView from 'views/dashboards/components/subjectsView';
// import AttendanceCalendar from 'views/dashboards/components/studentAttendence';
import AttendanceTableCalendar from 'views/dashboards/components/studentAttendence';
import AssessmentComponent from 'views/dashboards/components/Assessment';
import Alerts from 'views/dashboards/components/Alerts';
import ProgressReport from 'views/dashboards/components/studentReports';
import SingleExamReport from 'views/dashboards/components/singleExamReports';

const dashboards = {
  elearning: lazy(() => import('views/dashboards/ElearningDashboard')),
  school: lazy(() => import('views/dashboards/SchoolDashboard')),
};
const courses = {
  explore: lazy(() => import('views/courses/CoursesExplore')),
  list: lazy(() => import('views/courses/CoursesList')),
  detail: lazy(() => import('views/courses/CoursesDetail')),
};
const quiz = {
  list: lazy(() => import('views/quiz/QuizList')),
  detail: lazy(() => import('views/quiz/QuizDetail')),
  result: lazy(() => import('views/quiz/QuizResult')),
};
const paths = {
  list: lazy(() => import('views/paths/PathsList')),
  detail: lazy(() => import('views/paths/PathsDetail')),
};

const instructor = {
  list: lazy(() => import('views/instructor/InstructorList')),
  detail: lazy(() => import('views/instructor/InstructorDetail')),
};
const misc = {
  player: lazy(() => import('views/misc/Player')),
  material: lazy(() => import('views/misc/Material')),
  syllabus: lazy(() => import('views/misc/Syllabus')),
};

const reports = {
  assessmentType : lazy(()=>import('views/dashboards/components/showReports'))
}
const exams = {
  subjectWiseExams : lazy(()=>import('views/dashboards/components/SubjectsWiseExams'))
}
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards/elearning`,
    },
    {
      path: `${appRoot}/dashboards`,
      // icon: 'home-garage',
      // label: 'menu.dashboards',
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboards/elearning`,
      subs: [
        { path: '/elearning', label: 'menu.elearning', component: dashboards.elearning },
        // { path: '/school', label: 'menu.school', component: dashboards.school },
      ],
    },
    // {
    //   path: `${appRoot}/courses`,
    //   label: 'menu.courses',
    //   icon: 'online-class',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/courses/explore`,
    //   subs: [
    //     { path: '/explore', label: 'menu.explore', component: courses.explore },
    //     { path: '/list', label: 'menu.list', component: courses.list },
    //     { path: '/detail', label: 'menu.detail', component: courses.detail },
    //   ],
    // },
    // {
    //   path: `${appRoot}/quiz`,
    //   label: 'menu.quiz',
    //   icon: 'quiz',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/quiz/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: quiz.list },
    //     { path: '/detail', label: 'menu.detail', component: quiz.detail },
    //     { path: '/result', label: 'menu.result', component: quiz.result },
    //   ],
    // },
    // {
    //   path: `${appRoot}/paths`,
    //   label: 'menu.paths',
    //   icon: 'destination',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/paths/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: paths.list },
    //     { path: '/detail', label: 'menu.detail', component: paths.detail },
    //   ],
    // },
    // {
    //   path: `${appRoot}/instructor`,
    //   label: 'menu.instructor',
    //   icon: 'lecture',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/instructor/list`,
    //   subs: [
    //     { path: '/list', label: 'menu.list', component: instructor.list },
    //     { path: '/detail', label: 'menu.detail', component: instructor.detail },
    //   ],
    // },
    // {
    //   path: `${appRoot}/misc`,
    //   label: 'menu.misc',
    //   icon: 'layout-5',
    //   exact: true,
    //   redirect: true,
    //   to: `${appRoot}/misc/player`,
    //   subs: [
    //     { path: '/player', label: 'menu.player', component: misc.player },
    //     { path: '/material', label: 'menu.material', component: misc.material },
    //     { path: '/syllabus', label: 'menu.syllabus', component: misc.syllabus },
    //   ],
    // },
  ],
  sidebarItems: [{
    path: "/subjects/homework",
    exact: true,
    component: Homework, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/subjects",
    exact: true,
    component: SubjectsView, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/exam",
    exact: true,
    component: ExamsComponent, // Assuming "exams.exam" is actually "Exam"
  }, {
    path: "/assessment",
    exact: true,
    component: Assessment, // Assuming "exams.exam" is actually "Exam"
  },
  {
    path: "/subject/assessment",
    exact: true,
    component: AssessmentComponent, // Assuming "exams.exam" is actually "Exam"
  },
  {
    path: "/subjectAssessment",
    exact: true,
    component: SubjectAssessments, // Assuming "exams.exam" is actually "Exam"
  },
  {
    path: "/attendence",
    exact: true,
    component: AttendanceTableCalendar, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/anual_report",
    exact: true,
    component: ProgressReport, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/single_report",
    exact: true,
    component: SingleExamReport, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/alerts",
    exact: true,
    component: Alerts, // Assuming "homework.homework" is actually "Homework"
  },
  {
    path: "/show-reports",
    exact: true,
    component: reports.assessmentType,  
  },
  {
    path: "/viewExams",
    exact: true,
    component: exams.subjectWiseExams,  
  }

],
};
export default routesAndMenuItems;
