import React, { useEffect, useState } from 'react';
import './reports.css'; // Optional: Add custom CSS for styling.
import useService from 'views/default/useService';
import { useGetAllCommonStudentInitialDataMutation, usePostCommonMutation } from 'slices/get/common';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ProgressReport = () => {
    // let data = {
    //     name: "Sunitha",
    //     standard: "Standard-2",
    //     section: "A",
    //     school: "Info Global School",
    //     address: "# 53 Kukatpally Hyderabad-72",
    //     subjects: [
    //       { name: "English", FA1: 85, FA2: 90, FA3: 78, FA4: 88, SA1: 92, SA2: 89, SA3: 95, grade: "A1", remarks: "Outstanding" },
    //       { name: "Maths", FA1: 80, FA2: 75, FA3: 85, FA4: 88, SA1: 90, SA2: 82, SA3: 84, grade: "A2", remarks: "Excellent" },
    //       { name: "EVS", FA1: 65, FA2: 70, FA3: 60, FA4: 72, SA1: 68, SA2: 75, SA3: 70, grade: "B2", remarks: "Needs practice" },
    //     ],
    //     gradingScale: [
    //       { range: "91 – 100", grade: "A1", remarks: "Outstanding performance, keep it up, is a star pupil." },
    //       { range: "81 - 90", grade: "A2", remarks: "Excellent, keep it up, can try to be perfect." },
    //       { range: "71 - 80", grade: "B1", remarks: "Satisfactory, keep up the good work" },
    //       { range: "61 - 70", grade: "B2", remarks: "Good but needs practice to improve." },
    //       { range: "51 - 60", grade: "C1", remarks: "Above average, can raise the grade." },
    //       { range: "36 - 50", grade: "C2", remarks: "Below average, needs hard work." },
    //       { range: "00 - 35", grade: "D", remarks: "Fail, needs very hard work, parental conference required." },
    //     ],
    //   };

      const { checkAccessTime, fetchAccessToken } = useService();
  const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation();
  // const [data, setData] = useState();
  const location = useLocation();
  // const query = new URLSearchParams(location.search)
  // const assessmentType = query.get('id')
  const currentPath = location.pathname;
  const history = useHistory()
  const [postCommon] = usePostCommonMutation();
  const [anualReportData, setAnualReportData] = useState(null); // Use null initially to check if data is loaded

  useEffect(() => {
    const access_token = fetchAccessToken();

    postCommon({
      ep: `assessment/reports_multiple`,
      token: access_token
    }).then((response) => {
      if (response.data.status) {
        setAnualReportData(response.data.data);
      } else {
        console.log("response.data........", response.data);
      }
      checkAccessTime(response, currentPath);
    });
  }, [currentPath]);

  if (!anualReportData) return <p>Loading...</p>; // Render a loading state while data is fetching

  const { school, address, name, standard, section, subjects, gradingScale } = anualReportData;

  return (
    <div className="progress-report">
      <header className="report-header">
        <h1>{school}</h1>
        <p>{address || "Address not provided"}</p>
        <h2>Annual Progress Report</h2>
      </header>

      <div className="student-info">
        <div className="student-details">
          <p><strong>Student Name:</strong> {name}</p>
          <p><strong>Standard:</strong> {standard}</p>
          <p><strong>Section:</strong> {section}</p>
        </div>
        <div className="student-photo">
          <img 
            src="https://via.placeholder.com/150" 
            alt="Student" 
            className="student-image"
          />
        </div>
      </div>

      <table className="report-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>FA-1</th>
            <th>FA-2</th>
            <th>FA-3</th>
            <th>FA-4</th>
            <th>SA-1</th>
            <th>SA-2</th>
            <th>SA-3</th>
            <th>Grade</th>
            <th>Overall Remarks</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject, index) => (
            <tr key={index}>
              <td>{subject.name}</td>
              <td>{subject["FA-1"] || "N/A"}</td>
              <td>{subject["FA-2"] || "N/A"}</td>
              <td>{subject["FA-3"] || "N/A"}</td>
              <td>{subject["FA-4"] || "N/A"}</td>
              <td>{subject["SA-1"] || "N/A"}</td>
              <td>{subject["SA-2"] || "N/A"}</td>
              <td>{subject["SA-3"] || "N/A"}</td>
              <td>{subject.grade || "Not Applicable"}</td>
              <td>{subject.overall_remarks || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="grading-scale">
        <h3>Grading Scale</h3>
        <table>
          <thead>
            <tr>
              <th>Marks Range</th>
              <th>Grade</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {gradingScale.map((scale, index) => (
              <tr key={index}>
                <td>{scale.range}</td>
                <td>{scale.grade}</td>
                <td>{scale.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Signature of Teacher</p>
        <p>Parents' Signature</p>
      </div>

      <footer className="report-footer d-flex justify-content-center">
        <button className="print-button" onClick={() => window.print()}>
          Print
        </button>
      </footer>
    </div>
  );
};

export default ProgressReport;

  