import React, { useEffect, useState } from 'react';
import './homework.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useService from 'views/default/useService';
import { usePostCommonMutation } from 'slices/get/common';

function Homework() {
  const initialHomeworkData = [
    {
      day: 'Monday',
      date: '2024-08-30',
      subjects: [
        {
          id: '1',
          name: 'Math',
          homeworks: [
            { homework: 'Complete exercises 1-10 on page 42' },
            { homework: 'Prepare for the quiz on chapter 2' },
          ],
        },
        {
          id: '2',
          name: 'English',
          homeworks: [
            { homework: 'Read Chapter 3 and write a summary' },
          ],
        },
      ],
    },
    {
      day: 'Tuesday',
      date: '2024-08-31',
      subjects: [
        {
          id: '3',
          name: 'Science',
          homeworks: [
            { homework: 'Prepare notes on photosynthesis' },
          ],
        },
        {
          id: '4',
          name: 'History',
          homeworks: [
            { homework: 'Complete the timeline of World War II' },
          ],
        },
      ],
    },
  ];
  let location = useLocation()
  const query = new URLSearchParams(location.search);
  const subjectID = query.get('subjectID');
  const [homeworkData, setHomeworkData] = useState(initialHomeworkData);
  const [showHistory, setShowHistory] = useState(false);
  const { checkAccessTime, fetchAccessToken } = useService();
    const [postCommon] = usePostCommonMutation();
    const currentPath = location.pathname;
    const [filteredHomeworkData,setFilteredHomeworkData] = useState([])


  useEffect(async()=>{
    await getHomeworksData()
  },[showHistory])

  const getHomeworksData = async() =>{
    const access_token = fetchAccessToken();
    let values = {
      "showHistory" : showHistory,
      subjectId : subjectID
    }
    await postCommon({ ep: `student/student_homework_data`,values, token: access_token }).then(
      (response) => {
        if(response.data.status){
          setFilteredHomeworkData(response.data.data)
          // setAssessmentData(response.data.data)
        }
        checkAccessTime(response, currentPath);


      }
    );
  }
  // const filteredHomeworkData = showHistory ? homeworkData : homeworkData.filter(dayData => dayData.date === '2024-12-31');


  // Get homework for the current date or all if history is shown

  return (
    <div className="homework-container">
      <h2>Daily Homework</h2>

      <label htmlFor="historyToggle" className="history-toggle">
        <input
          type="checkbox"
          id="historyToggle"
          checked={showHistory}
          onChange={() => setShowHistory(!showHistory)}
        />
        Show History
      </label>

      <div className="homework-list">
  {filteredHomeworkData.length > 0 ? (
    filteredHomeworkData.map((dayData, index) => (
      <div key={index} className="day-card">
        <h3 className="day-title">{dayData.day} - {dayData.date}</h3>
        <div className="subjects">
          {dayData.subjects.length > 0 ? (
            dayData.subjects.map((subjectData) => (
              <div key={subjectData.id} className="subject-card">
                <h4 className="subject-title">{subjectData.name}</h4>
                {subjectData.homeworks.length > 0 ? (
                  <ul className="homework-list">
                    {subjectData.homeworks.map((homework, idx) => (
                      <li key={idx} className="homework-item">
                        {homework.homework}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="no-homework">No Homework Available</div>
                )}
              </div>
            ))
          ) : (
            <div className="no-subjects">No Subjects Available</div>
          )}
        </div>
      </div>
    ))
  ) : (
    <div className="no-homework-data">No Homework Data Available</div>
  )}
</div>

    </div>
  );
}

export default Homework;
