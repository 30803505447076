import React, { memo, Suspense, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import RouteItem from './RouteItem';
import useService from 'views/default/useService';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'auth/authSlice';

const RouteIdentifier = ({ routes, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND }) => {
  const dispatch = useDispatch();
  const { brightAcceptorHandle, setWorkingElements, getWorkingElements } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  const params = new URLSearchParams(location.search);
  const { serviceAcceptor, serviceProvider } = getWorkingElements();

  const { redirect = '', refresh = '' } = {
    redirect: params.get('redirect') && JSON.parse(atob(params.get('redirect'))),
    refresh: params.get('refresh_redirect') && JSON.parse(atob(params.get('refresh_redirect'))),
  };

  useEffect(() => {
    if (redirect) {
      console.log(redirect);

      if (redirect.serviceAcceptor && redirect.serviceProvider) {
        setWorkingElements(redirect.serviceAcceptor, redirect.serviceProvider);

        const brightAcceptor = brightAcceptorHandle();
        console.log(brightAcceptor);
        const storedAccessRole = brightAcceptor.role;

        if (storedAccessRole === 'student') {
          console.log(storedAccessRole, serviceAcceptor, serviceProvider);
          window.location.href = process.env.REACT_APP_STUDENT_APP;
        } else {
          window.location.href = process.env.REACT_APP_CONTAINER_APP;
        }
      } else {
        window.location.href = process.env.REACT_APP_CONTAINER_APP;
      }
    } else if (refresh) {
      console.log('refresh', refresh);

      if (refresh.newServiceAcceptor && refresh.serviceProvider) {
        setTimeout(() => {
          setWorkingElements(refresh.newServiceAcceptor, refresh.serviceProvider);
        }, 100);

        window.location.href = `${process.env.REACT_APP_STUDENT_APP}${currentPath}`;
      } else {
        window.location.href = process.env.REACT_APP_CONTAINER_APP;
      }
    } else if (serviceAcceptor && serviceProvider) {
      const brightAcceptor = brightAcceptorHandle();
      console.log(brightAcceptor);
      const storedAccessRole = brightAcceptor.role;

      if (storedAccessRole && storedAccessRole === 'student') {
        console.info(storedAccessRole, serviceAcceptor, serviceProvider);
        dispatch(setCurrentUser(brightAcceptor));
      } else {
        window.location.href = process.env.REACT_APP_CONTAINER_APP;
      }
    } else {
      window.location.href = process.env.REACT_APP_CONTAINER_APP;
    }
  }, []);

  return (
    <Suspense fallback={fallback}>
      <Switch>
        {routes.map((route, rIndex) => (
          <RouteItem key={`r.${rIndex}`} {...route} />
        ))}
        <Redirect to={notFoundPath} />
      </Switch>
    </Suspense>
  );
};

export default memo(RouteIdentifier);
