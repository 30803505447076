// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-report {
    width: 80%;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .report-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .student-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  }
  
  .student-photo {
    flex: 1 1;
    display: flex;
    justify-content: end;
    margin-bottom: 10px; /* Add spacing on smaller screens */
  }
  
  .report-table, .grading-scale table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .report-table th, .report-table td, .grading-scale th, .grading-scale td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }
  
  .grading-scale {
    margin-top: 20px;
  }
  
  .report-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .print-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .print-button:hover {
    background-color: #45a049;
  }
  `, "",{"version":3,"sources":["webpack://./src/views/dashboards/components/reports.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe,EAAE,uCAAuC;EAC1D;;EAEA;IACE,SAAO;IACP,aAAa;IACb,oBAAoB;IACpB,mBAAmB,EAAE,mCAAmC;EAC1D;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".progress-report {\n    width: 80%;\n    margin: auto;\n    font-family: Arial, sans-serif;\n  }\n  \n  .report-header {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .student-info {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n    flex-wrap: wrap; /* Ensure wrapping on smaller screens */\n  }\n  \n  .student-photo {\n    flex: 1;\n    display: flex;\n    justify-content: end;\n    margin-bottom: 10px; /* Add spacing on smaller screens */\n  }\n  \n  .report-table, .grading-scale table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n  }\n  \n  .report-table th, .report-table td, .grading-scale th, .grading-scale td {\n    border: 1px solid #000;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .grading-scale {\n    margin-top: 20px;\n  }\n  \n  .report-footer {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .print-button {\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n  }\n  \n  .print-button:hover {\n    background-color: #45a049;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
