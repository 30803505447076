import React, { useEffect, useState } from 'react';
import './singleExamReports.css'; // Optional CSS for styling.
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAllCommonStudentInitialDataMutation, usePostCommonMutation } from 'slices/get/common';
import useService from 'views/default/useService';

const SingleExamReport = () => {
  // const data = {
  //   name: "Sunitha",
  //   standard: "Standard-2",
  //   section: "A",
  //   school: "Info Global School",
  //   address: "# 53 Kukatpally Hyderabad-72",
  //   subjects: [
  //     { name: "English", totalMarks: 100, obtainedMarks: 85, grade: "A1", pass: true, remarks: "Outstanding" },
  //     { name: "Maths", totalMarks: 100, obtainedMarks: 78, grade: "A2", pass: true, remarks: "Excellent" },
  //     { name: "EVS", totalMarks: 100, obtainedMarks: 65, grade: "B2", pass: true, remarks: "Needs Practice" },
  //     { name: "GK", totalMarks: 50, obtainedMarks: 40, grade: "B1", pass: true, remarks: "Good Work" },
  //     { name: "Telugu", totalMarks: 100, obtainedMarks: 55, grade: "C1", pass: true, remarks: "Can Improve" },
  //     { name: "Hindi", totalMarks: 50, obtainedMarks: 25, grade: "D", pass: false, remarks: "Failed" },
  //   ],
  // };

  const { checkAccessTime, fetchAccessToken } = useService();
  const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation();
  const [reportData, setReportData] = useState({
    school: "",
    address: "",
    name: "",
    standard: "",
    section: "",
    subjects: []
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search)
  const assessmentType = query.get('id')
  const currentPath = location.pathname;
  const history = useHistory()
  const [postCommon] = usePostCommonMutation();
  // const [isOk,setIsOk] = useState(false)
  
  useEffect(() => {
    const access_token = fetchAccessToken();
    let values = {
      assessment_type : assessmentType
    }

     postCommon({ ep: `assessment/reports_single`,values, token: access_token }).then(
      (response) => {
        if(response.data.status){
          
          setReportData(response.data.data)
          // setIsOk(true)
        }else{
          console.log('response.data........',response.data)
        }
        checkAccessTime(response, currentPath);
      }
    );
  }, []);

  return (
    
    <div className="format1-report">
      <header className="report-header">
        <h1>{reportData.school}</h1>
        <p>{reportData.address}</p>
        <h2>Formative - 1 Progress Report</h2>
      </header>

      <div className="student-info">
        <div className="student-details">
          <p><strong>Student Name:</strong> {reportData.name}</p>
          <p><strong>Standard:</strong> {reportData.standard}</p>
          <p><strong>Section:</strong> {reportData.section}</p>
        </div>
        <div className="student-photo">
          <img 
            src="https://via.placeholder.com/150" 
            alt="Student" 
            className="student-image"
          />
        </div>
      </div>

      <table className="report-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Total Marks</th>
            <th>Obtained Marks</th>
            <th>Grade</th>
            <th>Pass/Fail</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {reportData.subjects.map((subject, index) => (
            <tr key={index}>
              <td>{subject.name}</td>
              <td>{subject.totalMarks}</td>
              <td>{subject.obtainedMarks}</td>
              <td>{subject.grade}</td>
              <td>{subject.pass ? "Pass" : "Fail"}</td>
              <td>{subject.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{display:'flex',justifyContent:'space-between'}}>
          <p>Signature of Teacher</p> <br />
          <p>Parents' Signature</p>
        </div>

      <footer className="report-footer d-flex justify-content-center">
        
        
        <button className="print-button" onClick={() => window.print()}>
          Print
        </button>
      </footer>
    </div>
  );
};

export default SingleExamReport;
