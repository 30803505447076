import React, { useEffect, useState } from "react";
import { Col, Card, Button, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useGetAllCommonStudentInitialDataMutation, usePostCommonMutation } from "slices/get/common";
import useService from "views/default/useService";

function AssessmentComponent() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const assessmentId = query.get('assessmentId');
  const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const [quizQuestions, setQuestionsData] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const currentPath = location.pathname;
  const [postCommon] = usePostCommonMutation();
  const access_token = fetchAccessToken();
  let studentData = sessionStorage.getItem('studentData')
  let student = JSON.parse(studentData)

  useEffect(() => {
   
    getAllCommonStudentInitialData({ ep: `student/assessment_questions?assessment_id=${assessmentId}&type=assessment`, token: access_token }).then(
      (response) => {
        if(response && response?.data && response.data.status){
          setQuestionsData(response.data.data);
        }
        checkAccessTime(response, currentPath);
      }
    );

  }, []);

  const handleOptionClick = (questionId, optionIndex) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: optionIndex,
    });
  };

  const changeIndex = () => {
    if (index < quizQuestions.length - 1) {
      setIndex(index + 1);
    }
  };

  const calculateScore = () => {
    return quizQuestions.reduce((score, question) => {
      if (selectedAnswers[question.id] === parseInt(question.answer)) {
        return score + 1;
      }
      return score;
    }, 0);
  };

  const countAnsweredQuestions = () => {
    return Object.keys(selectedAnswers).length;
  };

  const handleSubmit = async() => {
    setIsSubmitted(true);
    const submittedAnswers = quizQuestions.map((question) => ({
      questionId: question.id,
      givenAnswer: selectedAnswers[question.id],
      correctAnswer: parseInt(question.answer),
    }));

    const totalScore = calculateScore();
    const answeredCount = countAnsweredQuestions();

    const values = {
      answers: submittedAnswers,
      totalScore: totalScore,
      answeredCount: answeredCount,
      assessment_id : assessmentId,
      assessment_type : quizQuestions[0].assessment_type,
      student_id :student[0].student_id
    };

    await postCommon({ ep: `assessment/create_assessment_question_answers`,values, token: access_token }).then(
      (response) => {
        if(response && response?.data && response.data.status){
          // setFilteredHomeworkData(response.data.data)
          // setAssessmentData(response.data.data)
        }
        checkAccessTime(response, currentPath);
      }
    );

  };

  return (
    <Col lg="12" className="mb-12">
      <h2 className="small-title">Assessment</h2>
      {quizQuestions.length > 0 && <Card className="sh-45 h-lg-100-card">
        <Card.Body className="h-100">
          {!isSubmitted ? (
            <div>
              <h2 className="mb-4">
                {index + 1}. {quizQuestions[index].queston}
              </h2>
              <Row>
                {[
                  quizQuestions[index].option1,
                  quizQuestions[index].option2,
                  quizQuestions[index].option3,
                  quizQuestions[index].option4,
                ].map((option, i) => (
                  <Col xs="6" key={i} className="mb-3">
                    {quizQuestions[index].isImageMode === "true" ? (
                      <img
                        src={option}
                        alt={`Option ${i + 1}`}
                        style={{ width: '100%', cursor: 'pointer', border: selectedAnswers[quizQuestions[index].id] === i + 1 ? '2px solid green' : 'none' }}
                        onClick={() => handleOptionClick(quizQuestions[index].id, i + 1)}
                      />
                    ) : (
                      <Button
                        variant={selectedAnswers[quizQuestions[index].id] === i + 1 ? "success" : "outline-primary"}
                        className="w-100"
                        onClick={() => handleOptionClick(quizQuestions[index].id, i + 1)}
                      >
                        {option}
                      </Button>
                    )}
                  </Col>
                ))}
              </Row>
              <div className="d-flex align-items-center justify-content-center">
                {index < quizQuestions.length - 1 ? (
                  <Button
                    variant="primary"
                    className="btn-icon btn-icon-start mt-3"
                    onClick={changeIndex}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    className="btn-icon btn-icon-start mt-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <h3>Your Answers:</h3>
              {quizQuestions.map((question, i) => (
                <div key={question.id} className="mb-4">
                  <h4>
                    {i + 1}. {question.queston}
                  </h4>
                  <Row>
                    {[question.option1, question.option2, question.option3, question.option4].map((option, j) => (
                      <Col xs="6" key={j} className="mb-3">
                        {question.isImageMode === "true" ? (
                          <img
                            src={option}
                            alt={`Option ${j + 1}`}
                            style={{ width: '100%', border: j + 1 === parseInt(question.answer) ? '2px solid green' : selectedAnswers[question.id] === j + 1 ? '2px solid red' : 'none' }}
                            disabled
                          />
                        ) : (
                          <Button
                            variant={
                              j + 1 === parseInt(question.answer)
                                ? "success"
                                : selectedAnswers[question.id] === j + 1
                                  ? "danger"
                                  : "outline-primary"
                            }
                            className="w-100"
                            disabled
                          >
                            {option}
                          </Button>
                        )}
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
              <h3>Total Score: {calculateScore()} / {quizQuestions.length}</h3>
              <h4>Questions Answered: {countAnsweredQuestions()} / {quizQuestions.length}</h4>
            </div>
          )}
        </Card.Body>
      </Card>}
    </Col>
  );
};

export default AssessmentComponent;
