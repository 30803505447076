import React, { useEffect, useState } from "react";
import { Col, Card, Button, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useGetAllCommonStudentInitialDataMutation, usePostCommonMutation } from "slices/get/common";
import useService from "views/default/useService";

function ExamsComponent() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const assessmentId = query.get('examId');
  const [getAllCommonStudentInitialData] = useGetAllCommonStudentInitialDataMutation()
  const { checkAccessTime, fetchAccessToken } = useService();
  const [quizQuestions,setQuestionsData] = useState([])
  const [index, setIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
const currentPath = location.pathname;
const [postCommon] = usePostCommonMutation();
const access_token = fetchAccessToken();
let studentData = sessionStorage.getItem('studentData')
let student = JSON.parse(studentData)
  useEffect(() => {
    
    getAllCommonStudentInitialData({ ep: `student/assessment_questions?assessment_id=${assessmentId}`, token: access_token }).then(
      (response) => {
        if(response && response?.data && response.data.status){
          setQuestionsData(response.data.data)
        }
        // console.log(response);
        // console.log('Custom data from after_request:', response);

        checkAccessTime(response, currentPath);

        // console.log('RESPONSE', response);

        // setSubjectData(response.data.data.subjectData);
        // setLessonData(response.data.data.lessonData);
        // setPartData(response.data.data.partData);
      }
    );
    
  }, []);
  // const quizQuestions = [
  //   {
  //     id: 1,
  //     question: "What is the capital of France?",
  //     options: ["Berlin", "Madrid", "Paris", "Rome"],
  //     answer: 2 // Index of the correct answer
  //   },
  //   {
  //     id: 2,
  //     question: "Which planet is known as the Red Planet?",
  //     options: ["Earth", "Mars", "Jupiter", "Venus"],
  //     answer: 1
  //   },
  //   {
  //     id: 3,
  //     question: "Who wrote 'To Kill a Mockingbird'?",
  //     options: ["Harper Lee", "Mark Twain", "F. Scott Fitzgerald", "Ernest Hemingway"],
  //     answer: 0
  //   },
  //   {
  //     id: 4,
  //     question: "What is the largest ocean on Earth?",
  //     options: ["Atlantic Ocean", "Indian Ocean", "Arctic Ocean", "Pacific Ocean"],
  //     answer: 3
  //   },
  //   {
  //     id: 5,
  //     question: "Which element has the chemical symbol 'O'?",
  //     options: ["Oxygen", "Gold", "Silver", "Iron"],
  //     answer: 0
  //   }
  // ];

  const handleOptionClick = (questionId, optionIndex) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: optionIndex,
    });
  };

  const changeIndex = () => {
    if (index < quizQuestions.length - 1) {
      setIndex(index + 1);
    }
  };

  const calculateScore = () => {
    return quizQuestions.reduce((score, question) => {
      // Parse the correct answer and compare it with the selected answer
      if (selectedAnswers[question.id] === parseInt(question.answer)) {
        return score + 1;
      }
      return score;
    }, 0);
  };

  const countAnsweredQuestions = () => {
    return Object.keys(selectedAnswers).length;
  };

  const handleSubmit = async() => {
    setIsSubmitted(true);

    // Prepare the array with question IDs and given answers
    const submittedAnswers = quizQuestions.map((question) => ({
      questionId: question.id,
      givenAnswer: selectedAnswers[question.id], // User's selected answer
      correctAnswer: parseInt(question.answer), 
      assessmentId : assessmentId,
      assessment_type : quizQuestions[0].assessment_type, // Correct answer from the question data
      student_id :student[0].student_id
    }));

    // Calculate total score and count of answered questions
    const totalScore = calculateScore();
    const answeredCount = countAnsweredQuestions();

    // Prepare the payload to send
    const values = {
      answers: submittedAnswers,
      totalScore: totalScore,
      answeredCount: answeredCount,
      assessment_id : assessmentId,
      assessment_type : quizQuestions[0].assessment_type,
      student_id :student[0].student_id
    };

    // Send the payload to your backend or log it to the console
    // console.log(submissionPayload);

    await postCommon({ ep: `assessment/create_assessment_question_answers`,values, token: access_token }).then(
      (response) => {
        if(response && response?.data && response.data.status){
          // setFilteredHomeworkData(response.data.data)
          // setAssessmentData(response.data.data)
        }
        checkAccessTime(response, currentPath);
      }
    );
  };

  return (
    <Col lg="12" className="mb-12">
      <h2 className="small-title">Exam</h2>
     {quizQuestions.length > 0 && <Card className="sh-45 h-lg-100-card">
        <Card.Body className="h-100">
          {!isSubmitted ? (
            <div>
              <h2 className="mb-4">
                {index + 1}. {quizQuestions[index].queston}
              </h2>
              <Row>
                {[
                  quizQuestions[index].option1,
                  quizQuestions[index].option2,
                  quizQuestions[index].option3,
                  quizQuestions[index].option4,
                ].map((option, i) => (
                  <Col xs="6" key={i} className="mb-3">
                    <Button
                      variant={
                        selectedAnswers[quizQuestions[index].id] === i + 1
                          ? "success"
                          : "outline-primary"
                      }
                      className="w-100"
                      onClick={() =>
                        handleOptionClick(quizQuestions[index].id, i + 1)
                      }
                    >
                      {option}
                    </Button>
                  </Col>
                ))}
              </Row>
              <div className="d-flex align-items-center justify-content-center">
                {index < quizQuestions.length - 1 ? (
                  <Button
                    variant="primary"
                    className="btn-icon btn-icon-start mt-3"
                    onClick={changeIndex}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    className="btn-icon btn-icon-start mt-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <h3>Your Answers:</h3>
              {quizQuestions.map((question, i) => (
                <div key={question.id} className="mb-4">
                  <h4>
                    {i + 1}. {question.queston}
                  </h4>
                  <Row>
                    {[question.option1, question.option2, question.option3, question.option4].map((option, j) => (
                      <Col xs="6" key={j} className="mb-3">
                        <Button
                          variant={
                            j + 1 === parseInt(question.answer)
                              ? "success"
                              : selectedAnswers[question.id] === j + 1
                              ? selectedAnswers[question.id] === parseInt(question.answer)
                                ? "success"
                                : "danger"
                              : "outline-primary"
                          }
                          className="w-100"
                          disabled
                        >
                          {option}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
              <h3>Total Score: {calculateScore()} / {quizQuestions.length}</h3>
              <h4>Questions Answered: {countAnsweredQuestions()} / {quizQuestions.length}</h4>
            </div>
          )}
        </Card.Body>
      </Card>}
    </Col>
  );
};




export default ExamsComponent;
