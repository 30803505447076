import React, { useState, useEffect } from 'react';
import './Alerts.css'; // Optional: Custom CSS for styling
import useService from 'views/default/useService';
import { useGetAllCommonStudentInitialDataMutation } from 'slices/get/common';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Alerts = () => {
    // const alertsData = [
    //     {
    //       id: 1,
    //       title: 'Homework Due Reminder',
    //       message: 'Complete your math homework by 5 PM today.',
    //       type: 'warning', // info, success, warning, or error
    //       timestamp: '2024-10-21T10:30:00Z'
    //     },
    //     {
    //       id: 2,
    //       title: 'Holiday Announcement',
    //       message: 'School will be closed on October 24th for Dussehra.',
    //       type: 'info',
    //       timestamp: '2024-10-20T08:15:00Z'
    //     },
    //     {
    //       id: 3,
    //       title: 'Attendance Alert',
    //       message: 'You were marked absent on October 19th.',
    //       type: 'error',
    //       timestamp: '2024-10-19T09:00:00Z'
    //     }
    //   ];
      let location = useLocation()
      // const [postCommon] = usePostCommonMutation();
      const { checkAccessTime, fetchAccessToken } = useService();
      const currentPath = location.pathname;


      
  const [alerts, setAlerts] = useState([]);
  const access_token = fetchAccessToken();
  const [getSchoolData] = useGetAllCommonStudentInitialDataMutation()


  useEffect(async()=>{
    await getSchoolData({ ep: '/student/alerts', token: access_token }).then(
      (response) => {
        if(response.data.status){
          setAlerts(response.data.data)
        }

        checkAccessTime(response, currentPath);

        // console.log('RESPONSE', response);

       
      }
    )
  },[])
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const backgroundColors = [
    "alert-bg-light1",
    "alert-bg-light2",
    "alert-bg-light3",
    "alert-bg-light4",
  ];
  // Handle dismissing an alert
  // const dismissAlert = (id) => {
  //   setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  // };

  return (
    <div className="alerts-container">
      <h2>Alerts</h2>
      {alerts.length > 0 ? (
        alerts.map((alert, index) => (
          <div
            key={alert.id}
            className={`alert-card ${
              backgroundColors[index % backgroundColors.length]
            }`}
          >
            <h4 className="alert-title">{alert.title}</h4>
            <p className="alert-message">{alert.alert}</p>
            {/* <div className="alert-footer">
              <span className="alert-type">{alert.alert_type}</span>
            </div> */}
            <div className="alert-dates">
              <span><strong>Start:</strong> {alert.start_date ? formatDate(alert.start_date) : "N/A"}</span>
              <span><strong>End:</strong> {alert.end_date ? formatDate(alert.end_date) : "N/A"}</span>
            </div>
          </div>
        ))
      ) : (
        <div className="no-alerts">No Alerts Available</div>
      )}
    </div>
  );
};

export default Alerts;
