// // AttendanceTableCalendar.js
// import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { getMonth, getYear } from 'date-fns';
// import './homework.css';

// // Mock Data: Replace with API data as needed
// const attendanceRecords = [
//     { student_id: 1, student_name: 'Satya', date: '2024-09-01', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-02', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-03', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-04', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-05', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-06', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-07', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-08', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-09', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-10', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-11', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-12', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-13', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-14', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-15', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-16', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-17', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-18', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-19', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-20', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-21', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-22', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-23', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-24', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-25', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-26', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-27', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-28', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-29', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-30', status: 'present' },
//   ];
  

// const AttendanceTableCalendar = () => {
//   const [selectedDate, setSelectedDate] = useState(new Date()); // Default to current date
//   const [filteredData, setFilteredData] = useState([]);

//   // Filter attendance data based on the selected month and year
//   useEffect(() => {
//     const month = getMonth(selectedDate); // Extract month (0-11)
//     const year = getYear(selectedDate);   // Extract year

//     const filtered = attendanceRecords.filter((record) => {
//       const recordDate = new Date(record.date);
//       return (
//         recordDate.getFullYear() === year &&
//         recordDate.getMonth() === month
//       );
//     });

//     setFilteredData(filtered);
//   }, [selectedDate]);

//   return (
//     <div className="calendar-container">
//       <h2>Attendance for Satya</h2>

//       {/* Date Picker for Month and Year */}
//       <div className="date-picker-container">
//         <DatePicker
//           selected={selectedDate}
//           onChange={(date) => setSelectedDate(date)}
//           dateFormat="MMMM yyyy" // Display month and year only
//           showMonthYearPicker // Limit picker to month and year
//           className="custom-date-picker"
//         />
//       </div>

//       {/* Attendance Table */}
//       <table className="attendance-table">
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.length > 0 ? (
//             filteredData.map((entry) => (
//               <tr key={entry.date} className={`status-${entry.status.toLowerCase()}`}>
//                 <td>{entry.date}</td>
//                 <td>{entry.status}</td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="2">No records found for this month and year</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default AttendanceTableCalendar;

// import React, { useState, useEffect } from 'react';
// import { format, getMonth, getYear, addDays } from 'date-fns';

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { format, getMonth, getYear } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './homework.css';
import useService from 'views/default/useService';
import {  usePostCommonMutation } from 'slices/get/common';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Row } from 'react-bootstrap';
const moment = require('moment');

// Mock Attendance Records
// const attendanceRecords = [
//     { student_id: 1, student_name: 'Satya', date: '2024-09-01', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-02', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-03', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-04', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-05', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-06', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-07', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-08', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-09', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-10', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-11', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-12', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-13', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-14', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-15', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-16', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-17', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-18', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-19', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-20', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-21', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-22', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-23', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-24', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-25', status: 'holiday' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-26', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-27', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-28', status: 'present' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-29', status: 'absent' },
//     { student_id: 1, student_name: 'Satya', date: '2024-09-30', status: 'present' },
//   ];
  

const AttendanceDashboard = () => {
    const { checkAccessTime, fetchAccessToken } = useService();
    const [postCommon] = usePostCommonMutation();


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [attendanceRecords,setAttendenceRecords] = useState([])

  const month = getMonth(selectedDate);
  const year = getYear(selectedDate);
  let location = useLocation()
  const currentPath = location.pathname;

  useEffect(async() => {
    await getStudentAttendenceData()
   
    // Filter attendance records based on selected month and year
  }, [selectedDate, month, year]);
  const getStudentAttendenceData = async () => {
    const access_token = fetchAccessToken();
    const values = { "date": moment(selectedDate).format('YYYY-MM-DD') };

    await postCommon({ ep: `student/student_attendance`, values, token: access_token })
      .then((response) => {
        if(response && response?.data && response.data.status){
          const data = response.data.data;
        //   setAttendenceRecords(data);

          // Filter attendance records based on the selected month and year
          const filtered = data.filter(record => {
            const recordDate = new Date(record.date);
            return (
              recordDate.getFullYear() === year &&
              recordDate.getMonth() === month
            );
          });

          setFilteredData(filtered);
        }
        checkAccessTime(response, currentPath);
      });
  };
  

  // Create a calendar view
  const createCalendar = () => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);
      const record = filteredData.find(rec => rec.date === format(date, 'yyyy-MM-dd'));
      const status = record ? record.status : 'none';

      calendar.push(
        <div key={i} className={`calendar-day status-${status}`}>
          {i}
        </div>
      );
    }

    return calendar;
  };

  return (
    <div className="attendance-dashboard">
      <h2>Attendance for {filteredData.length ? filteredData[0].student_name : 'Student'}</h2>
      <Row 
  spacing={2} 
  style={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    flexWrap: 'wrap', 
    width: '100%' 
  }}
>
  {/* Date Picker: Full Width on Small Screens, Half Width on Medium+ Screens */}
  <Col xs={12} md={3} className="date-picker-container">
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      className="custom-date-picker"
      popperPlacement="bottom"
      popperModifiers={{
        offset: { enabled: true, offset: '0, 10' },
      }}
    />
  </Col>

  {/* Status Indicators: Adjust Alignment for Mobile */}
  <Col 
    xs={12} 
    md={6} 
    className="d-flex align-items-center justify-content-md-end justify-content-center"
    style={{ marginLeft: 'auto' }}
  >
    <div className="d-flex align-items-center me-3">
      <span
        style={{
          backgroundColor: '#4CAF50',
          borderRadius: '50%',
          width: '12px',
          height: '12px',
          display: 'inline-block',
          marginRight: '8px',
        }}
      />
      <p className="mb-0">Present</p>
    </div>

    <div className="d-flex align-items-center me-3">
      <span
        style={{
          backgroundColor: '#FF5252',
          borderRadius: '50%',
          width: '12px',
          height: '12px',
          display: 'inline-block',
          marginRight: '8px',
        }}
      />
      <p className="mb-0">Absent</p>
    </div>

    <div className="d-flex align-items-center">
      <span
        style={{
          backgroundColor: '#FFEB3B',
          borderRadius: '50%',
          width: '12px',
          height: '12px',
          display: 'inline-block',
          marginRight: '8px',
        }}
      />
      <p className="mb-0">Holiday</p>
    </div>
  </Col>
</Row>

      

      {/* Date Picker for Month and Year */}
      

      {/* Calendar Display */}
      <div className="calendar">{createCalendar()}</div>

      {/* Attendance Records Table */}
      <h3>Attendance Records</h3>
      <table className="attendance-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map(entry => (
              <tr key={entry.date} className={`status-${entry.status.toLowerCase()}`}>
                <td>{entry.date}</td>
                <td>{entry.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No records found for this month</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceDashboard;
