import CryptoJS from 'crypto-js';

const useService = () => {
  const getWorkingElements = () => {
    const serviceAcceptor = localStorage.getItem('service_acceptor');
    const serviceProvider = localStorage.getItem('service_provider');

    return { serviceAcceptor, serviceProvider };
  };
  const shadeAcceptor = (serviceAcceptor, serviceProvider) =>
    CryptoJS.AES.encrypt(JSON.stringify(serviceAcceptor), serviceProvider).toString();
  const lightAcceptor = (serviceAcceptor, serviceProvider) =>
    JSON.parse(CryptoJS.AES.decrypt(serviceAcceptor, serviceProvider).toString(CryptoJS.enc.Utf8));
  const brightAcceptorHandle = () => {
    const { serviceAcceptor, serviceProvider } = getWorkingElements();
    return lightAcceptor(serviceAcceptor, serviceProvider);
  };
  const setWorkingElements = (serviceAcceptor, serviceProvider) => {
    localStorage.setItem('service_acceptor', serviceAcceptor);
    localStorage.setItem('service_provider', serviceProvider);
  };
  const handleLogout = () => {
    localStorage.removeItem('service_provider');
    localStorage.removeItem('service_acceptor');
    window.location.href = `${process.env.REACT_APP_CONTAINER_APP}?logout=true`;
  };
  const checkAccessTime = (response, currentPath) => {
    if (response.data) {
      if (response.data.error) {
        if (response.data.error.status === 'UN_AUTHORISED') {
          console.error('Handle Logout');
          handleLogout();
        }
      } else if (response.data.access_token) {
       
        const brightAcceptor = brightAcceptorHandle();

        const serviceProvider = localStorage.getItem('service_provider');

        let newServiceAcceptor = shadeAcceptor(
          { ...brightAcceptor, access_token: response.data.access_token },
          serviceProvider
        );

        setTimeout(() => {
          setWorkingElements(newServiceAcceptor, serviceProvider);
        }, 100);

        window.location.href = `${process.env.REACT_APP_CONTAINER_APP}?refresh=${btoa(
          JSON.stringify({ token: response.data.access_token, route: currentPath })
        )}`;
      }
    } else if (response.error) {
      console.error('Check Access Time Error');
      if (response.error.status) {
        console.error('Response Error', response);

        if (response.error.status === 'FETCH_ERROR') {
          console.error('FETCH_ERROR');
          //handleLogout();
        }
      }
    }
  };

  const fetchAccessToken = () => {
    const brightAcceptor = brightAcceptorHandle();
    const { access_token } = brightAcceptor;

    return access_token;
  };

  return {
    getWorkingElements,
    shadeAcceptor,
    lightAcceptor,
    brightAcceptorHandle,
    checkAccessTime,
    handleLogout,
    setWorkingElements,
    fetchAccessToken,
  };
};

export default useService;
